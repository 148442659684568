import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import classNames from "classnames";
import { defaultTo, isEmpty, isNil, remove, update } from "ramda";

import { Button, Dropdown, LinkHelpText, P, Ui } from "common/components/atoms";
import { ImportTable } from "common/components/atoms/ImportTable";
import { AltinnOnlyIcon, UnlistedIcon, WarningIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../styles.module.scss";
import { fields } from "../shareholders-at-year-end/shareholders-edit-form/use-edit-shareholders-form";
import { AroShareholder } from "../types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearStart.body");

type DataProps = AroShareholder & { selectedId?: number };

type InternalProps = {
  altinnShareholder: DataProps;
  unlistedShareholder: DataProps | null;
  aroShareholderId: number;
}[];

const ShareholdersUnmatched = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const getShareholderAtYearStart = useStoreActions((store) => store.aroModel.getShareholdersAtYearStartThunk);

  const unmatachedShareholders = useStoreState(
    (state) => state.aroModel.shareholdersAtYearStart?.unmatachedShareholders
  );
  const unmatchedUnlistedStakeholders = useStoreState(
    (state) => state.aroModel.shareholdersAtYearStart?.unmatchedUnlistedStakeholders
  );

  const [dataValues, setDataValues] = useState<InternalProps>([] as unknown as InternalProps);
  const [shareholdersListUsage, setShareholdersListUsage] = useState<number[]>([]);

  const dropdownOptions = useMemo(() => {
    return unmatchedUnlistedStakeholders || [];
  }, [unmatchedUnlistedStakeholders]);

  const columns = useMemo(() => {
    const cells: ColumnDef<InternalProps[number]>[] = [
      {
        header: t("conflicts.tableColumns.shareholder"),
        accessorKey: "shareholder",
        cell: (props) => {
          const altinnItem: DataProps = props.row.original["altinnShareholder"];

          return (
            <>
              <div className={classNames("d-flex align-items-center", classes["row"])}>
                <LinkHelpText
                  title=""
                  placement="top"
                  overlayClass={classes["tooltip"]}
                  content={t("conflicts.tableColumns.dataFromAltinn")}
                >
                  <div
                    className={classNames("ms-2 me-1_half", classes["avatar-card"], {
                      [classes["altinn"]]: true,
                    })}
                  >
                    <AltinnOnlyIcon />
                  </div>
                </LinkHelpText>

                <Ui.s>{altinnItem.name}</Ui.s>
              </div>

              <div className={classNames("d-flex align-items-center", classes["row"])}>
                <LinkHelpText
                  title=""
                  overlayClass={classes["tooltip"]}
                  content={t("conflicts.tableColumns.dataFromUnlisted")}
                  placement="top"
                >
                  <div
                    className={classNames("ms-2 me-1_half", classes["avatar-card"], {
                      [classes["altinn"]]: false,
                    })}
                  >
                    <UnlistedIcon />
                  </div>
                </LinkHelpText>

                <Dropdown
                  selectedValue={dataValues?.[props.row.index]?.unlistedShareholder?.selectedId}
                  onChange={(e) => {
                    let optionsValues: number[] = shareholdersListUsage;

                    const selectedItem = dropdownOptions.find(
                      (el) => el.stakeholderId === Number(e.target.value)
                    ) as AroShareholder;

                    if (
                      dataValues?.[props.row.index]?.unlistedShareholder?.selectedId &&
                      dataValues?.[props.row.index]?.unlistedShareholder?.selectedId !== Number(e.target.value)
                    ) {
                      const getIdIndex = shareholdersListUsage.findIndex(
                        (el) => el === dataValues?.[props.row.index]?.unlistedShareholder?.selectedId
                      );

                      optionsValues = remove(getIdIndex, 1, optionsValues);
                    }

                    setShareholdersListUsage([...optionsValues, Number(e.target.value)]);

                    const updatedValues = update(
                      props.row.index,
                      {
                        altinnShareholder: altinnItem,
                        aroShareholderId: dataValues[props.row.index].aroShareholderId,
                        unlistedShareholder: { ...selectedItem, selectedId: Number(e.target.value) },
                      },
                      dataValues
                    );

                    setDataValues(updatedValues);
                  }}
                  optionsIsObject
                  options={dropdownOptions.map((el) => ({
                    name: el.name,
                    id: el.stakeholderId,
                    isDisabled: shareholdersListUsage.includes(el.stakeholderId),
                  }))}
                />
              </div>
            </>
          );
        },
        minSize: 300,
      },
      {
        header: t("conflicts.tableColumns.isin"),
        accessorKey: fields.isin,
        cell: (props) => {
          const [altinnItem, unlistedItem]: [DataProps, DataProps | null] = [
            props.row.original["altinnShareholder"],
            props.row.original?.["unlistedShareholder"],
          ];

          return (
            <>
              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo("-", altinnItem?.isCompanyOwned ? altinnItem?.organizationNumber : altinnItem?.isin)}
              </div>

              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo(
                  "-",
                  unlistedItem?.organizationNumber ? unlistedItem?.organizationNumber : unlistedItem?.isin
                )}
              </div>
            </>
          );
        },
        minSize: 140,
      },
      {
        header: t("conflicts.tableColumns.address"),
        accessorKey: fields.adresse,
        cell: (props) => {
          const [altinnItem, unlistedItem]: [DataProps, DataProps | null] = [
            props.row.original["altinnShareholder"],
            props.row.original?.["unlistedShareholder"],
          ];

          return (
            <>
              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo("-", altinnItem.address)}
              </div>

              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo("-", unlistedItem?.address)}
              </div>
            </>
          );
        },
        minSize: 180,
      },
      {
        header: t("conflicts.tableColumns.postNumber"),
        accessorKey: fields.postnummer,
        cell: (props) => {
          const [altinnItem, unlistedItem]: [DataProps, DataProps | null] = [
            props.row.original["altinnShareholder"],
            props.row.original?.["unlistedShareholder"],
          ];

          return (
            <>
              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo("-", altinnItem?.postalCode)}
              </div>

              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo("-", unlistedItem?.postalCode)}
              </div>
            </>
          );
        },
        minSize: 120,
      },
      {
        header: t("conflicts.tableColumns.postSted"),
        accessorKey: fields.poststed,
        cell: (props) => {
          const [altinnItem, unlistedItem]: [DataProps, DataProps | null] = [
            props.row.original["altinnShareholder"],
            props.row.original?.["unlistedShareholder"],
          ];

          return (
            <>
              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo("-", altinnItem?.postalCity)}
              </div>

              <div
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {defaultTo("-", unlistedItem?.postalCity)}
              </div>
            </>
          );
        },
        minSize: 120,
      },
    ];

    return cells;
  }, [dataValues, dropdownOptions, shareholdersListUsage]);

  const table = useReactTable({
    columns,
    data: dataValues,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    meta: { footerShow: false },
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleSubmit = async () => {
    try {
      const dataToSend = dataValues.map((el) => {
        if (el?.unlistedShareholder) {
          return {
            aroShareholderId: el.aroShareholderId,
            stakeholderId: el?.unlistedShareholder?.selectedId,
          };
        }
      });

      const request = await axios.post("/api/aro/shareholder-conflict/no-match", {
        companyId,
        conflicts: dataToSend,
      });

      if (request.status === 200) {
        getShareholderAtYearStart(String(companyId));
      }
    } catch (e) {
      console.error({ e });
    }
  };

  useEffect(() => {
    if (!isNil(unmatachedShareholders) && !isEmpty(unmatachedShareholders)) {
      setDataValues(
        unmatachedShareholders.map((el) => ({
          altinnShareholder: { ...el.altinnShareholder, selectedId: undefined },
          aroShareholderId: el.aroShareholderId,
          unlistedShareholder: null,
        }))
      );
    }
  }, [unmatachedShareholders]);

  if (isEmpty(unmatachedShareholders) || isNil(unmatachedShareholders)) {
    return null;
  }

  return (
    <div className={classNames(classes.backgroundElement, "mb-1_half rounded-3 p-3 cursor-pointer")}>
      <div className="d-flex justify-content-between align-items-center mb-semihalf">
        <P.s className="w-75">{t("shareholdersUnableToMatch")}</P.s>
        <Button
          onClick={handleSubmit}
          isDisabled={
            !dataValues?.every((el) => el?.altinnShareholder?.selectedId || el?.unlistedShareholder?.selectedId)
          }
        >
          Large
        </Button>
      </div>

      <ImportTable table={table} className="w-100" />

      <div className={classes["no-matches-found"]}>
        <WarningIcon className="ms-3 me-1" /> <Ui.xs>{t("noMatchesFound")}</Ui.xs>
      </div>
    </div>
  );
};

export default ShareholdersUnmatched;
